import create from "zustand"
import { persist } from "zustand/middleware"
import Analytics from 'lib/Analytics'
import EventPubSub from 'lib/EventPubSub'

const validBadgeIds = [
  'fishing',
  'biking',
  'paddling',
  'camping',
  'hiking',
  'climbing',
];

const useStore = create(persist(
  (set, get) => ({
    badgesAcquired: [],
    resetBadgesAcquired: () => {
      set({
        badgesAcquired: [],
      });
    },
    addBadgeAcquired: (badgeId) => {
      let ba = get().badgesAcquired.slice();
      if (ba.indexOf(badgeId) < 0 && validBadgeIds.indexOf(badgeId) >= 0) {
        ba.push(badgeId);

        if (ba.length === validBadgeIds.length) { // Are all acheived
          Analytics.badgeAcheived(badgeId);
          Analytics.allBadgesAcheived();
          EventPubSub.emit('modal-allbadgesacheived-open');
        }
        else { // Just one completed
          Analytics.badgeAcheived(badgeId);
          EventPubSub.emit('modal-badgeacheived-open', badgeId);
        }

        set({ badgesAcquired: ba });
      }
    },
  }),
  {
    name: "public-lands", // unique name
  }
));

export default useStore;
