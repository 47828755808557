import React from 'react'
import s from './Footer.module.scss'

import Button from 'components/Button'

import imgLogo from 'assets/images/pl-logo-footer.svg'
import imgSocialIconInstagram from 'assets/images/logo-ig-brown.svg'
import imgSocialIconTwitter from 'assets/images/logo-tw-brown.svg'
import imgSocialIconFacebook from 'assets/images/logo-fb-brown.svg'
import imgSocialIconInstagramActive from 'assets/images/logo-ig-brown-dark.svg'
import imgSocialIconTwitterActive from 'assets/images/logo-tw-brown-dark.svg'
import imgSocialIconFacebookActive from 'assets/images/logo-fb-brown-dark.svg'

Footer.defaultProps = {
  callToActionText: 'We Exist to Celebrate and Protect Public Lands for All',
  callToActionLabel: 'Learn More',
  callToActionURL: 'https://www.publiclands.com/',
  socialInstagramURL: 'https://www.instagram.com/publiclandsshop/',
  socialTwitterURL: 'https://twitter.com/publiclandsshop',
  socialFacebookURL: 'https://www.facebook.com/publiclandsshop',
  links: [
    {
      name: 'Privacy Policy',
      href: 'https://www.publiclands.com/s/privacy-policy'
    },
    {
      name: 'Terms of Use',
      href: 'https://www.publiclands.com/s/terms-of-use'
    },
    {
      name: 'Contact Us',
      href: 'https://www.publiclands.com/s/help-desk'
    },
  ],
};

export default function Footer(props) {
  const {
    callToActionText,
    callToActionLabel,
    callToActionURL,
    socialInstagramURL,
    socialTwitterURL,
    socialFacebookURL,
    links,
  } = props;

  const now = new Date();

  return (
    <div className={s.Footer}>
      <div 
      className={s.CallToAction}
      id="mobile-learn-more"
      >
        <div>{callToActionText}</div>
        <Button
          theme='red-orange'
          href={callToActionURL}
          target="_blank"
          rel="noreferrer"
        >
          {callToActionLabel}
        </Button>
      </div>

      <div className={s.Divider}></div>

      <div className={s.Bottom}>
        <img className={s.Logo} src={imgLogo} alt="Public Lands" />
        <div className={s.Social}>
          <a href={socialInstagramURL} target="_blank" rel="noreferrer">
            <img
              className={s.SocialIconInstagram}
              src={imgSocialIconInstagram}
              alt="Instagram"
            />
            <img
              className={s.SocialIconInstagram}
              src={imgSocialIconInstagramActive}
              alt="Instagram"
            />
          </a>
          <a href={socialTwitterURL} target="_blank" rel="noreferrer">
            <img
              className={s.SocialIconTwitter}
              src={imgSocialIconTwitter}
              alt="Twitter"
            />
            <img
              className={s.SocialIconTwitter}
              src={imgSocialIconTwitterActive}
              alt="Twitter"
            />
          </a>
          <a href={socialFacebookURL} target="_blank" rel="noreferrer">
            <img
              className={s.SocialIconFacebook}
              src={imgSocialIconFacebook}
              alt="Facebook"
            />
            <img
              className={s.SocialIconFacebook}
              src={imgSocialIconFacebookActive}
              alt="Facebook"
            />
          </a>
        </div>
        <div className={s.Links}>
          {links.map((link, index) => (
            <a
              key={'link-'+index}
              href={link.href}
              target="_blank"
              rel="noreferrer"
            >
              {link.name}
            </a>
          ))}
        </div>
        <div className={s.Legal}>
          Copyright {now.getFullYear()}
          <span>|</span>
          All Rights Reserved
        </div>
      </div>
    </div>
  );
}
