import React, { useState, useEffect } from 'react'
import EventPubSub from 'lib/EventPubSub'
import s from './ModalBadgeAcheived.module.scss'
import Modal from 'components/Modal'
import Button from 'components/Button'

import imgHero from 'assets/images/congrats-hero.jpg'
import imgBadgeFishing from 'assets/images/badge-1.png'
import imgBadgeBiking from 'assets/images/badge-2.png'
import imgBadgePaddling from 'assets/images/badge-3.png'
import imgBadgeCamping from 'assets/images/badge-4.png'
import imgBadgeHiking from 'assets/images/badge-5.png'
import imgBadgeClimbing from 'assets/images/badge-6.png'

ModalBadgeAcheived.defaultProps = {
  heading: 'Congratulations!',
  copy: 'You’ve unlocked a merit badge!',
  badges: [
    {
      id: 'fishing',
      image: {
        src: imgBadgeFishing,
        alt: 'Let’s Cast Together',
      },
    },
    {
      id: 'biking',
      image: {
        src: imgBadgeBiking,
        alt: 'Let’s Bike Together',
      },
    },
    {
      id: 'paddling',
      image: {
        src: imgBadgePaddling,
        alt: 'Let’s Paddle Together',
      },
    },
    {
      id: 'camping',
      image: {
        src: imgBadgeCamping,
        alt: 'Let’s Camp Together',
      },
    },
    {
      id: 'hiking',
      image: {
        src: imgBadgeHiking,
        alt: 'Let’s Hike Together',
      },
    },
    {
      id: 'climbing',
      image: {
        src: imgBadgeClimbing,
        alt: 'Let’s Climb Together',
      },
    },
  ],
  callToActionLabel: 'Back to Public Lands',
};

export default function ModalBadgeAcheived(props) {
  const {
    heading,
    copy,
    badges,
    callToActionLabel,
  } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [badge, setBadge] = useState(null);

  function onOpen(badgeId) {
    for (let i = 0; i < badges.length; i++) {
      if (badges[i].id === badgeId) {
        setBadge(badges[i]);
        break;
      }
    }
    setIsOpen(true);
  }
  function onClose(e) {
    if (e) e.preventDefault();
    setIsOpen(false);
  }

  useEffect(() => {
    EventPubSub.subscribe('modal-badgeacheived-open', onOpen);
    EventPubSub.subscribe('modal-badgeacheived-close', onClose);

    return () => {
      EventPubSub.unsubscribe('modal-badgeacheived-open', onOpen);
      EventPubSub.unsubscribe('modal-badgeacheived-close', onClose);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className={s.ModalBadgeAcheived}>
        <img className={s.Hero} src={imgHero} alt={heading} />
        <h2>{heading}</h2>
        <p>{copy}</p>
        {badge &&
          <div className={s.Badge}>
            <img src={badge.image.src} alt={badge.image.alt} />
          </div>
        }
        <Button theme='red-orange' onClick={onClose}>{callToActionLabel}</Button>
      </div>
    </Modal>
  );
}
