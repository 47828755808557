import React from 'react'
import s from './Button.module.scss'

Button.defaultProps = {
  children: [],
  href: '#',
  target: '_self',
  rel: null,
  onClick: null,
  theme: 'bright-green', // 'bright-green','red-orange'
};

export default function Button(props) {
  const {
    children,
    href,
    target,
    rel,
    onClick,
    theme,
  } = props;

  return (
    <a
      className={s.Button+' '+s[theme]}
      href={href}
      target={target}
      rel={rel}
      onClick={onClick}
    >
      {children}
    </a>
  );
}
