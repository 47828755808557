import React, { useState, useEffect, useRef } from 'react'
import ReactModal from 'react-modal'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import getUniqueId from 'lib/getUniqueId';

import imgCloseIcon from 'assets/images/x.png'
import imgCloseIconTan from 'assets/images/x_tan.png'

ReactModal.setAppElement('#root');

Modal.defaultProps = {
  children: null,
  isOpen: false,
  onClose: null,
  closeIconTheme: 'default', // 'default', 'tan'
  fullWidth: true,
  fullHeight: true,
};

export default function Modal(props) {
  const {
    children,
    isOpen,
    onClose,
    closeIconTheme,
    fullWidth,
    fullHeight,
  } = props;

  const [id, setId] = useState(null);
  const disableScrollTimeout = useRef(null);

  let additionalClassNames = '';
  if (fullWidth) additionalClassNames += ' full-width';
  if (fullHeight) additionalClassNames += ' full-height';

  useEffect(() => {
    if (disableScrollTimeout.current !== null) {
      clearTimeout(disableScrollTimeout.current);
    }

    if (isOpen) {
      disableScrollTimeout.current = setTimeout(() => {
        let contentEl = document.getElementById(id);
        if (contentEl) {
          disableBodyScroll(contentEl);
        }
      }, 100);
    }
    else {
      let contentEl = document.getElementById(id);
      if (contentEl) {
        enableBodyScroll(contentEl);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    setId( getUniqueId('modal') );
  }, []);

  return (
      <ReactModal isOpen={isOpen}
        closeTimeoutMS={600}
        portalClassName="modal-portal"
        overlayClassName="modal-overlay"
        onRequestClose={onClose}
        className={"modal-content" + additionalClassNames}
        id={id}
      >
        <button className="close" type="button" onClick={onClose}>
          {closeIconTheme === 'default' &&
            <img src={imgCloseIcon} alt="Close" />
          }
          {closeIconTheme === 'tan' &&
            <img src={imgCloseIconTan} alt="Close" />
          }
        </button>
        {children}
      </ReactModal>
  );
}
