import React from 'react'
import s from './DesktopNotice.module.scss'

import imgBG from 'assets/images/bg-desktop.jpg'
import imgLogoTop from 'assets/images/pl-logo-desktop-top.svg'
import imgQR from 'assets/images/qr-desktop.png'
import imgLogoBottom from 'assets/images/pl-logo-desktop-bottom.svg'
import imgSocialIconInstagram from 'assets/images/logo-ig-white.svg'
import imgSocialIconTwitter from 'assets/images/logo-tw-white.svg'
import imgSocialIconFacebook from 'assets/images/logo-fb-white.svg'

DesktopNotice.defaultProps = {
  heading: 'Scan QR Code',
  // copy: 'Head into the Public Lands grand opening event in Framingham, MA any time from October 14-16, 2022. Scan the code on your mobile device to participate in the in-store geocaching experience.',
  
  // Huntington
  // copy: 'Head into the Public Lands grand opening event in Huntington, NY any time from October 21-23, 2022. Scan the code on your mobile device to participate in the in-store geocaching experience.',
  
  // Kennesaw
  // copy: 'Head into the Public Lands grand opening event in Kennesaw, GA any time from October 28-30, 2022. Scan the code on your mobile device to participate in the in-store geocaching experience.',
  
  // Medford
  copy: 'Head into the Public Lands grand opening event in Medford, OR any time from November 4-6, 2022. Scan the code on your mobile device to participate in the in-store geocaching experience.',

  callToActionLabel: 'Shop Public Lands',
  callToActionURL: 'https://www.publiclands.com/',
  footerCopy: 'Let’s Explore Together',
  socialInstagramURL: 'https://www.instagram.com/publiclandsshop/',
  socialTwitterURL: 'https://twitter.com/publiclandsshop',
  socialFacebookURL: 'https://www.facebook.com/publiclandsshop',
};

export default function DesktopNotice (props) {
  const {
    heading,
    copy,
    callToActionLabel,
    callToActionURL,
    footerCopy,
    socialInstagramURL,
    socialTwitterURL,
    socialFacebookURL,
  } = props;

  return (
    <div className={s.DesktopNotice}>
      <div className={s.Notice} style={{
        backgroundImage: 'url(' + imgBG + ')',
      }}>
        <div className={s.Content}>
          <img className={s.LogoTop} src={imgLogoTop} alt="Public Lands" />
          <img className={s.QrCode} src={imgQR} alt="QR Code" />
          <h1 className={s.Heading}>{heading}</h1>
          <p className={s.Copy}>{copy}</p>
          <a className={s.Button}
            href={callToActionURL}
            target='_blank'
            rel="noreferrer"
          >
            {callToActionLabel}
          </a>
          <img className={s.LogoBottom} src={imgLogoBottom} alt="Public Lands" />
          <p className={s.CopyBottom}>{footerCopy}</p>
          <div className={s.Social}>
            <a href={socialInstagramURL} target="_blank" rel="noreferrer">
              <img
                className={s.SocialIconInstagram}
                src={imgSocialIconInstagram}
                alt="Instagram"
              />
            </a>
            <a href={socialTwitterURL} target="_blank" rel="noreferrer">
              <img
                className={s.SocialIconTwitter}
                src={imgSocialIconTwitter}
                alt="Twitter"
              />
            </a>
            <a href={socialFacebookURL} target="_blank" rel="noreferrer">
              <img
                className={s.SocialIconFacebook}
                src={imgSocialIconFacebook}
                alt="Facebook"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
