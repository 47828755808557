import React from 'react'
import EventPubSub from 'lib/EventPubSub'
import useStore from 'lib/useStore'
import s from './HomeStartExploring.module.scss'

import Button from 'components/Button'

import imgBadgeFishing from 'assets/images/badge-1.png'
import imgBadgeBiking from 'assets/images/badge-2.png'
import imgBadgePaddling from 'assets/images/badge-3.png'
import imgBadgeCamping from 'assets/images/badge-4.png'
import imgBadgeHiking from 'assets/images/badge-5.png'
import imgBadgeClimbing from 'assets/images/badge-6.png'

// import imgMap1 from 'assets/images/map-framingham.png'
// import imgMap1 from 'assets/images/map-huntington.png'
// import imgMap1 from 'assets/images/map-kennesaw.png'
import imgMap1 from 'assets/images/map-medford.png'


// import imgMapExpand from 'assets/images/expand.png'
import imgPin from 'assets/images/pin.svg'
import imgPinAcquired from 'assets/images/pin-acquired.svg'

HomeStartExploring.defaultProps = {
  heading: 'Start Exploring',
  badges: [
    {
      id: 'fishing',
      image: {
        src: imgBadgeFishing,
        alt: 'Let’s Cast Together',
      },
    },
    {
      id: 'biking',
      image: {
        src: imgBadgeBiking,
        alt: 'Let’s Bike Together',
      },
    },
    {
      id: 'paddling',
      image: {
        src: imgBadgePaddling,
        alt: 'Let’s Paddle Together',
      },
    },
    {
      id: 'camping',
      image: {
        src: imgBadgeCamping,
        alt: 'Let’s Camp Together',
      },
    },
    {
      id: 'hiking',
      image: {
        src: imgBadgeHiking,
        alt: 'Let’s Hike Together',
      },
    },
    {
      id: 'climbing',
      image: {
        src: imgBadgeClimbing,
        alt: 'Let’s Climb Together',
      },
    },
  ],
  storeLayout: {
    image: {
      src: imgMap1,
      alt: 'Store Map',
    },
    pins: [
      
      // FRAMINGHAM
      // {
      //   badgeId: 'fishing',
      //   top: '25.5%',
      //   left: '29.5%',
      // },

      // HUNTINGTON
      // {
      //   badgeId: 'fishing',
      //   top: '17%',
      //   left: '17%',
      // },

      // KENNESAW
      // {
      //   badgeId: 'fishing',
      //   top: '17%',
      //   left: '12%',
      // },

      // MEDFORD
      {
        badgeId: 'fishing',
        top: '32%',
        left: '13.5%',
      },


      // FRAMINGHAM
      // {
      //   badgeId: 'biking',
      //   top: '22%',
      //   left: '52%',
      // },

      // HUNTINGTON
      // {
      //   badgeId: 'biking',
      //   top: '55%',
      //   left: '16%',
      // },

      // KENNESAW
      // {
      //   badgeId: 'biking',
      //   top: '76%',
      //   left: '27%',
      // },

      // MEDFORD
      {
        badgeId: 'biking',
        top: '81%',
        left: '24%',
      },


      // FRAMINGHAM
      // {
      //   badgeId: 'paddling',
      //   top: '14.5%',
      //   left: '37%',
      // },

      // // HUNTINGTON
      // {
      //   badgeId: 'paddling',
      //   top: '36%',
      //   left: '24%',
      // },

      // KENNESAW
      // {
      //   badgeId: 'paddling',
      //   top: '30%',
      //   left: '65%',
      // },

      // MEDFORD
      {
        badgeId: 'paddling',
        top: '55%',
        left: '17.5%',
      },


      // FRAMINGHAM
      // {
      //   badgeId: 'camping',
      //   top: '59%',
      //   left: '72%',
      // },
      
      // HUNTINGTON
      // {
      //   badgeId: 'camping',
      //   top: '47%',
      //   left: '72%',
      // },

      // KENNESAW
      // {
      //   badgeId: 'camping',
      //   top: '61%',
      //   left: '80%',
      // },

      // MEDFORD
      {
        badgeId: 'camping',
        top: '53.5%',
        left: '83%',
      },


      // FRAMINGHAM
      // {
      //   badgeId: 'hiking',
      //   top: '75%',
      //   left: '29.5%',
      // },

      // HUNTINGTON
      // {
      //   badgeId: 'hiking',
      //   top: '32%',
      //   left: '77.5%',
      // },

      // KENNESAW
      // {
      //   badgeId: 'hiking',
      //   top: '28%',
      //   left: '85%',
      // },

      // MEDFORD
      {
        badgeId: 'hiking',
        top: '22%',
        left: '76%',
      },


      // FRAMINGHAM
      // {
      //   badgeId: 'climbing',
      //   top: '81%',
      //   left: '56%',
      // },

      // HUNTINGTON
      // {
      //   badgeId: 'climbing',
      //   top: '15%',
      //   left: '73%',
      // },

      // KENNESAW
      // {
      //   badgeId: 'climbing',
      //   top: '53.5%',
      //   left: '18%',
      // },

      // MEDFORD
      {
        badgeId: 'climbing',
        top: '18.5%',
        left: '64%',
      },
    ]
  },
};

export default function HomeStartExploring (props) {
  const {
    heading,
    badges,
    storeLayout,
  } = props;

  const badgesAcquired = useStore(state => state.badgesAcquired);

  function onClickMapExpand (e) {
    if (e) e.preventDefault();
    EventPubSub.emit('modal-mapzoomed-open');
  }
  function onClickScan (e) {
    if (e) e.preventDefault();
    EventPubSub.emit('modal-qrscanner-open');
  }

  return (
    <div className={s.HomeStartExploring}>
      <h2>{heading}</h2>
      <div className={s.Badges}>
        {badges.map((badge, index) => {
          let hasBeenAcquired = false;
          for (let i = 0; i < badgesAcquired.length; i++) {
            if (badgesAcquired[i] === badge.id) {
              hasBeenAcquired = true;
              break;
            }
          }

          return (
            <img
              key={'badge-' + index}
              src={badge.image.src}
              alt={badge.image.alt}
              style={{
                opacity: (hasBeenAcquired ? 1.0 : 0.2),
              }}
            />
          );
        })}
      </div>
      <div className={s.BadgesAcquiredBar}>
        <div />
        <span>{badgesAcquired.length}</span>/{badges.length} Merit Badges Acquired
        <div />
      </div>
      <div className={s.MapContainer} onClick={onClickMapExpand}>
        <img className={s.Map} src={storeLayout.image.src} alt={storeLayout.image.alt} />
        <div className={s.Pins}>
          {storeLayout.pins.map((pin, index) => {
            let hasBeenAcquired = false;
            for (let i = 0; i < badgesAcquired.length; i++) {
              if (badgesAcquired[i] === pin.badgeId) {
                hasBeenAcquired = true;
                break;
              }
            }

            return (
              <div key={'pin-' + index} className={s.MapPin} style={{
                top: pin.top,
                left: pin.left,
              }}>
                <div className={s.MapPinInner}>
                  {hasBeenAcquired &&
                    <img src={imgPinAcquired} alt='Map pin' />
                  }
                  {!hasBeenAcquired &&
                    <>
                      <img src={imgPin} alt='Map pin' />
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </>
                  }
                </div>
              </div>
            );
          })}
        </div>
        {/* <div className={s.MapExpand}>
          <img src={imgMapExpand} alt='Expand' />
        </div> */}
      </div>
      <div id="mobile-scan-here" className={s.ButtonContainer}>
        <Button theme="bright-green" onClick={onClickScan}>
          Scan Here
        </Button>
      </div>
    </div>
  );
}
