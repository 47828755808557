import React, { useState, useEffect } from 'react'
import EventPubSub from 'lib/EventPubSub'
import s from './ModalAllBadgesAcheived.module.scss'
import Modal from 'components/Modal'
import Button from 'components/Button'

import imgHero from 'assets/images/congrats-hero.jpg'
import imgBadgeFishing from 'assets/images/badge-1.png'
import imgBadgeBiking from 'assets/images/badge-2.png'
import imgBadgePaddling from 'assets/images/badge-3.png'
import imgBadgeCamping from 'assets/images/badge-4.png'
import imgBadgeHiking from 'assets/images/badge-5.png'
import imgBadgeClimbing from 'assets/images/badge-6.png'

ModalAllBadgesAcheived.defaultProps = {
  heading: 'Congratulations!',
  copy: 'You’ve unlocked all 6 merit badges. Enjoy your collected\nitems on your next outdoor adventure, or turn in any\nunwanted items to a Public Lands ambassador.',
  badges: [
    {
      id: 'fishing',
      image: {
        src: imgBadgeFishing,
        alt: 'Let’s Cast Together',
      },
    },
    {
      id: 'biking',
      image: {
        src: imgBadgeBiking,
        alt: 'Let’s Bike Together',
      },
    },
    {
      id: 'paddling',
      image: {
        src: imgBadgePaddling,
        alt: 'Let’s Paddle Together',
      },
    },
    {
      id: 'camping',
      image: {
        src: imgBadgeCamping,
        alt: 'Let’s Camp Together',
      },
    },
    {
      id: 'hiking',
      image: {
        src: imgBadgeHiking,
        alt: 'Let’s Hike Together',
      },
    },
    {
      id: 'climbing',
      image: {
        src: imgBadgeClimbing,
        alt: 'Let’s Climb Together',
      },
    },
  ],
  endHeading: 'See you next time, adventurer.',
  callToActionLabel: 'Back to Public Lands',
};

export default function ModalAllBadgesAcheived(props) {
  const {
    heading,
    copy,
    badges,
    endHeading,
    callToActionLabel,
  } = props;

  const [isOpen, setIsOpen] = useState(false);

  function onOpen() {
    setIsOpen(true);
  }
  function onClose(e) {
    if (e) e.preventDefault();
    setIsOpen(false);
  }

  useEffect(() => {
    EventPubSub.subscribe('modal-allbadgesacheived-open', onOpen);
    EventPubSub.subscribe('modal-allbadgesacheived-close', onClose);

    return () => {
      EventPubSub.unsubscribe('modal-allbadgesacheived-open', onOpen);
      EventPubSub.unsubscribe('modal-allbadgesacheived-close', onClose);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className={s.ModalAllBadgesAcheived}>
        <img className={s.Hero} src={imgHero} alt={heading} />
        <h2>{heading}</h2>
        <p>{copy}</p>
        <div className={s.Badges}>
          {badges.map((badge, index) => (
            <img key={'badge-'+index} src={badge.image.src} alt={badge.image.alt} />
          ))}
        </div>
        <h3>{endHeading}</h3>
        <Button theme='red-orange' onClick={onClose}>{callToActionLabel}</Button>
      </div>
    </Modal>
  );
}
