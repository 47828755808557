import React from 'react'
import s from './NotFound.module.scss'

const NotFound = ({children}) => {
  
  return (
    <div className={s.NotFound}>
      <h1>Page Not Found</h1>
      <p>Looks like we couldn't find the page you were looking for.</p>
    </div>
  )

}

export default NotFound