import React from 'react'
import s from './HomeSearchToWin.module.scss'

HomeSearchToWin.defaultProps = {
  heading: 'Search to Win',
  copy: 'Use the map below to navigate through the store to each geocache location. Once there, look around to find a hidden display with a QR code and prizes. Scan the QR code, unlock a merit badge and collect your prize before moving on to the next location. Collect all six badges to complete the activity.',
};

export default function HomeSearchToWin(props) {
  const {
    heading,
    copy,
  } = props;

  return (
    <div className={s.HomeSearchToWin}>
      <h2>{heading}</h2>
      <p>{copy}</p>
    </div>
  );
}
