

export default class Analytics {
  /**
   * Page view event
   * @param  {String} url
   */
  static pageView(url) {
    console.log('[Analytics] pageView('+url+')');
    const GTAG_ID = 'G-LKJSF37S7G';

    // Google Tag Manager
    if (window.gtag) {
      window.gtag('config', GTAG_ID, { 'page_location': url });
    }
  }

  /**
   * Event: Single Badge Acheived
   */
  static badgeAcheived(badgeId) {
    console.log('[Analytics] badgeAcheived('+badgeId+')');

    // Google Tag Manager
    if (window.gtag) {
      window.gtag('event', 'Achieved', { 'event_category': 'Badge', 'event_label': badgeId });
    }
  }

  /**
   * Event: All Badges Acheived
   */
  static allBadgesAcheived() {
    console.log('[Analytics] allBadgesAcheived()');

    // Google Tag Manager
    if (window.gtag) {
      window.gtag('event', 'Achieved', { 'event_category': 'All Badges', 'event_label': 'All Badges' });
    }
  }
}
