import React from 'react'
import Home from 'views/Home'
import NotFound from 'views/NotFound'
import 'styles/index.scss';

import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

function App() {
  return (
    <div className="App">
      <Router basename='/'>
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/*">
            <NotFound />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
