import React from 'react'
import s from './HomeHero.module.scss'

import imgSticker from 'assets/images/eplt_badge.png'

HomeHero.defaultProps = {
  heading: 'Let’s Explore Together.',
  copy: 'Join the Public Lands geocaching experience',
  sticker: {
    src: imgSticker,
    alt: 'Explore Public Lands Together',
  },
};

export default function HomeHero(props) {
  const {
    heading,
    copy,
    sticker,
  } = props;

  return (
    <div className={s.HomeHero}>
      <h1>{heading}</h1>
      <p>{copy}</p>
      <img className={s.Sticker} src={sticker.src} alt={sticker.alt} />
    </div>
  );
}
