import React, { useEffect } from 'react'
import { isDesktop } from 'react-device-detect'
import useStore from 'lib/useStore'
import s from './Home.module.scss'

import MainLayout from 'layouts/Main'
import DesktopNotice from 'components/DesktopNotice'
import Header from 'components/Header'
import HomeHero from 'components/HomeHero'
import HomeSearchToWin from 'components/HomeSearchToWin'
import HomeStartExploring from 'components/HomeStartExploring'
import HomeBottom from 'components/HomeBottom'
import Footer from 'components/Footer'

import ModalMapZoomed from 'components/ModalMapZoomed'
import ModalBadgeAcheived from 'components/ModalBadgeAcheived'
import ModalAllBadgesAcheived from 'components/ModalAllBadgesAcheived'
import ModalQRScanner from 'components/ModalQRScanner'

import imgBG from 'assets/images/bg.jpg'

export default function Home(props) {
  const addBadgeAcquired = useStore(state => state.addBadgeAcquired);

  function processQueryParams() {
    const urlParams = new URLSearchParams(window.location.search);
    const badgeId = urlParams.get('badge');
    if (badgeId) {
      addBadgeAcquired(badgeId);
    }
  }

  useEffect(() => {
    if (!isDesktop) {
      processQueryParams();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isDesktop) {
    return (
      <MainLayout>
        <div className={s.Home}>
          <DesktopNotice />
        </div>
      </MainLayout>
    );
  }
  return (
    <MainLayout>
      <div className={s.Home}>
        <Header />
        <div className={s.HomeContent} style={{
          backgroundImage: 'url('+imgBG+')',
        }}>
          <HomeHero />
          <HomeSearchToWin />
          <HomeStartExploring />
          <HomeBottom />
        </div>
        <Footer />

        <ModalMapZoomed />
        <ModalBadgeAcheived />
        <ModalAllBadgesAcheived />
        <ModalQRScanner />
      </div>
    </MainLayout>
  );

}
