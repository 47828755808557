import React from 'react'
import s from './Main.module.scss'

const Main = ({children}) => {

  return (
    <div className={s.Main}>
      {children}
    </div>
  )

}

export default Main
