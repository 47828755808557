
class EventPubSub {
  constructor() {
    this.events = {};
  }

  subscribe(name, cb) {
    if (!this.events[name]) {
      this.events[name] = [];
    }
    this.events[name].push(cb);
  }

  unsubscribe(name, cb) {
    if (this.events[name]) {
      this.events[name].splice(this.events[name].indexOf(cb), 1);
    }
  }

  emit(name, ...args) {
    if (this.events[name]) {
      for (let i = 0; i < this.events[name].length; i++) {
        if (args) {
          this.events[name][i](...args);
        }
        else {
          this.events[name][i]();
        }
      }
    }
  }
}
let eventEmitter = new EventPubSub();

export default eventEmitter;
