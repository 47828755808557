import React, { useState, useEffect } from 'react'
import { QrReader } from '@blackbox-vision/react-qr-reader';
import EventPubSub from 'lib/EventPubSub'
import useStore from 'lib/useStore'
import s from './ModalQRScanner.module.scss'
import Modal from 'components/Modal'

ModalQRScanner.defaultProps = {
  badgeQrURLs: [
    {
      qrURL: 'https://delivr.com/2zrg6',
      badgeId: 'fishing',
    },
    {
      qrURL: 'https://delivr.com/2n9e3',
      badgeId: 'biking',
    },
    {
      qrURL: 'https://delivr.com/2x3vp',
      badgeId: 'paddling',
    },
    {
      qrURL: 'https://delivr.com/2ymzz',
      badgeId: 'camping',
    },
    {
      qrURL: 'https://delivr.com/2x2h8',
      badgeId: 'hiking',
    },
    {
      qrURL: 'https://delivr.com/2eqvv',
      badgeId: 'climbing',
    },
  ],
};

export default function ModalQRScanner(props) {
  const {
    badgeQrURLs,
  } = props;

  const addBadgeAcquired = useStore(state => state.addBadgeAcquired);
  const [isOpen, setIsOpen] = useState(false);

  function onOpen() {
    setIsOpen(true);
  }
  function onClose(e) {
    if (e) e.preventDefault();
    setIsOpen(false);
  }

  function onError(err) {
    if ((err.name && err.name === 'NotFoundError')
      || (err.name && err.name === 'NotAllowedError')) {
      onClose();
      alert(err.message);
    }
    else {
      // console.log('QR Scan Error: ', err.name, err);
    }
  }
  function onScan(data) {
    if (data) {
      // alert("Scanned QR: "+data);
      // console.log('onScan('+data+')');

      let lowerData = data.toLowerCase();

      for (let i = 0; i < badgeQrURLs.length; i++) {
        let badgeQrURL = badgeQrURLs[i];
        if (lowerData.indexOf(badgeQrURL.qrURL) >= 0) {
          addBadgeAcquired(badgeQrURL.badgeId);
          onClose();
        }
      }

      // if (data.indexOf('badge=') >= 0) {
      //   let start = data.indexOf('badge=') + 6;
      //   let end = undefined;
      //   if (data.indexOf('&', start) >= 0) {
      //     end = data.indexOf('&');
      //   }
      //   let badgeId = data.substring(start, end);
      //   // console.log('badgeId = ', badgeId);
      //   addBadgeAcquired(badgeId);
      //   onClose();
      // }
    }
  }

  useEffect(() => {
    EventPubSub.subscribe('modal-qrscanner-open', onOpen);
    EventPubSub.subscribe('modal-qrscanner-close', onClose);

    return () => {
      EventPubSub.unsubscribe('modal-qrscanner-open', onOpen);
      EventPubSub.unsubscribe('modal-qrscanner-close', onClose);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className={s.ModalQRScanner}>
        <QrReader
          onResult={(result, error) => {
            if (result)
              onScan(result.text);
            else if (error)
              onError(error);
          }}
          constraints={{
            aspectRatio: 1,
            facingMode: 'environment',
          }}
          className="qr-reader"
        />
      </div>
    </Modal>
  );
}
