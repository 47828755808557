import React from 'react'
import s from './Header.module.scss'

import imgLogo from 'assets/images/pl-logo-header.svg'

Header.defaultProps = {
};

export default function Header(props) {
  // const { } = props;

  return (
    <div className={s.Header}>
      <img className={s.Logo} src={imgLogo} alt="Public Lands" />
    </div>
  );
}
