import React from 'react'
import s from './HomeBottom.module.scss'

import imgLogo from 'assets/images/pl-logo-large.svg'

HomeBottom.defaultProps = {
};

export default function HomeBottom(props) {
  // const { } = props;

  return (
    <div className={s.HomeBottom}>
      <img src={imgLogo} alt='Pacific Lands' />
    </div>
  );
}
