import React, { useState, useEffect, useRef } from 'react'
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import EventPubSub from 'lib/EventPubSub'
import useStore from 'lib/useStore'
import s from './ModalMapZoomed.module.scss'
import Modal from 'components/Modal'

// import imgMap1Zoomed from 'assets/images/map-framingham-xl.png'
// import imgMap1Zoomed from 'assets/images/map-huntington-xl.png'
// import imgMap1Zoomed from 'assets/images/map-kennesaw-xl.png'
import imgMap1Zoomed from 'assets/images/map-medford-xl.png'



import imgPin from 'assets/images/pin.svg'
import imgPinAcquired from 'assets/images/pin-acquired.svg'

ModalMapZoomed.defaultProps = {
  storeLayout: {
    image: {
      src: imgMap1Zoomed,
      alt: 'Store Map',
      width: 1495,
      height: 1521,
    },
    pins: [
      
      // FRAMINGHAM
      // {
      //   badgeId: 'fishing',
      //   top: '25.5%',
      //   left: '29.5%',
      // },
      
      // HUNTINGTON
      // {
      //   badgeId: 'fishing',
      //   top: '17%',
      //   left: '17%',
      // },

      // KENNESAW
      // {
      //   badgeId: 'fishing',
      //   top: '17%',
      //   left: '12%',
      // },

      // MEDFORD
      {
        badgeId: 'fishing',
        top: '32%',
        left: '13.5%',
      },

      
      // FRAMINGHAM
      // {
      //   badgeId: 'biking',
      //   top: '22%',
      //   left: '52%',
      // },
      
      // HUNTINGTON
      // {
      //   badgeId: 'biking',
      //   top: '55%',
      //   left: '16%',
      // },

      // KENNESAW
      // {
      //   badgeId: 'biking',
      //   top: '76%',
      //   left: '27%',
      // },

      // MEDFORD
      {
        badgeId: 'biking',
        top: '81%',
        left: '24%',
      },

      
      // FRAMINGHAM
      // {
      //   badgeId: 'paddling',
      //   top: '14.5%',
      //   left: '37%',
      // },
      
      // // HUNTINGTON
      // {
      //   badgeId: 'paddling',
      //   top: '36%',
      //   left: '24%',
      // },

      // KENNESAW
      // {
      //   badgeId: 'paddling',
      //   top: '30%',
      //   left: '65%',
      // },

      // MEDFORD
      {
        badgeId: 'paddling',
        top: '55%',
        left: '17.5%',
      },

      
      // FRAMINGHAM
      // {
      //   badgeId: 'camping',
      //   top: '59%',
      //   left: '72%',
      // },
     
      // HUNTINGTON
      // {
      //   badgeId: 'camping',
      //   top: '47%',
      //   left: '72%',
      // },

      // KENNESAW
      // {
      //   badgeId: 'camping',
      //   top: '61%',
      //   left: '80%',
      // },

      // MEDFORD
      {
        badgeId: 'camping',
        top: '53.5%',
        left: '83%',
      },


      // FRAMINGHAM
      // {
      //   badgeId: 'hiking',
      //   top: '75%',
      //   left: '29.5%',
      // },
      
      // HUNTINGTON
      // {
      //   badgeId: 'hiking',
      //   top: '32%',
      //   left: '77.5%',
      // },

      // KENNESAW
      // {
      //   badgeId: 'hiking',
      //   top: '28%',
      //   left: '85%',
      // },

      // MEDFORD
      {
      badgeId: 'hiking',
      top: '22%',
      left: '76%',
      },

      
      // FRAMINGHAM
      // {
      //   badgeId: 'climbing',
      //   top: '81%',
      //   left: '56%',
      // },
      
      // HUNTINGTON
      // {
      //   badgeId: 'climbing',
      //   top: '15%',
      //   left: '73%',
      // },

      // KENNESAW
      // {
      //   badgeId: 'climbing',
      //   top: '53.5%',
      //   left: '18%',
      // },

      // MEDFORD
      {
        badgeId: 'climbing',
        top: '18.5%',
        left: '64%',
      },
    ]
  },
};

export default function ModalMapZoomed (props) {
  const {
    storeLayout,
  } = props;

  const badgesAcquired = useStore(state => state.badgesAcquired);
  const transformWrapperRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  function onOpen () {
    setIsOpen(true);
  }
  function onClose (e) {
    if (e) e.preventDefault();
    setIsOpen(false);
  }

  function onMapImageLoad () {
    transformWrapperRef.current.resetTransform();
  }

  useEffect(() => {
    EventPubSub.subscribe('modal-mapzoomed-open', onOpen);
    EventPubSub.subscribe('modal-mapzoomed-close', onClose);

    return () => {
      EventPubSub.unsubscribe('modal-mapzoomed-open', onOpen);
      EventPubSub.unsubscribe('modal-mapzoomed-close', onClose);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      fullHeight={true}
      closeIconTheme='tan'
    >
      <div className={s.ModalMapZoomed}>
        <TransformWrapper
          ref={transformWrapperRef}
          initialScale={0.4}
          maxScale={2}
          minScale={0.25}
          centerOnInit={true}
        >
          <TransformComponent
            wrapperStyle={{
              height: '100%',
              maxWidth: '100vw',
            }}
          >
            <div className={s.MapWrapper}>
              <div className={s.MapContainer}>
                <img
                  className={s.Map}
                  src={storeLayout.image.src}
                  alt={storeLayout.image.alt}
                  onLoad={onMapImageLoad}
                />
                <div className={s.Pins}>
                  {storeLayout.pins.map((pin, index) => {
                    let hasBeenAcquired = false;
                    for (let i = 0; i < badgesAcquired.length; i++) {
                      if (badgesAcquired[i] === pin.badgeId) {
                        hasBeenAcquired = true;
                        break;
                      }
                    }

                    return (
                      <div key={'pin-' + index} className={s.MapPin} style={{
                        top: pin.top,
                        left: pin.left,
                      }}>
                        <div className={s.MapPinInner}>
                          {hasBeenAcquired &&
                            <img src={imgPinAcquired} alt='Map pin' />
                          }
                          {!hasBeenAcquired &&
                            <>
                              <img src={imgPin} alt='Map pin' />
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                            </>
                          }
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </TransformComponent>
        </TransformWrapper>
      </div>
    </Modal>
  );
}
